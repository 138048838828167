<template>
  <section id="section3" class="program">
    <h2 class="center title">プログラム紹介</h2>
    <div class="container pc">
      <div class="program-main-wrapper">
        <p class="program-main-headline">メインパフォーマンス</p>
        <div class="program-main-inner">
          <template v-for="mainProgram in mainPrograms" :key="mainProgram.id">
            <div
              v-for="program in mainProgram.column1"
              :key="program.num"
              class="row flow program-main"
              :id="program.num"
            >
              <div class="col span-3">
                <img :src="program.img" alt="プログラム" />
              </div>
              <div class="col span-9 program-text">
                <h3>{{ program.ttl }}</h3>
                <p v-html="program.date"></p>
                <p v-html="program.txt"></p>
              </div>
            </div>
            <div
              v-for="program in mainProgram.column2"
              :key="program.num"
              class="row flow program-main"
              :id="program.num"
            >
              <div class="col span-9 program-text">
                <h3>
                  {{ program.ttl }}
                </h3>
                <p v-html="program.date"></p>
                <p v-html="program.txt"></p>
                <BaseReserveBtn v-if="program.form" :link="program.link">
                  <template v-if="program.txtLink" v-slot:txtLink>
                    <span class="txt-link" v-html="program.txtLink"></span>
                  </template>
                </BaseReserveBtn>
              </div>
              <div class="col span-3">
                <img :src="program.img" alt="プログラム" />
              </div>
            </div>
          </template>
        </div>
      </div>
      <template v-for="row in Rows" :key="row.id">
        <div class="row program-sub">
          <template v-for="r in row" :key="r.id">
            <div class="col span-4" :id="r.num">
              <img :src="r.img" alt="プログラム" />
              <h3>{{ r.ttl }}</h3>
              <p v-html="r.date"></p>
              <p v-html="r.txt"></p>
              <BaseReserveBtn v-if="r.form" :link="r.link" />
            </div>
          </template>
        </div>
      </template>
    </div>

    <div class="container sp">
      <div class="program-main-wrapper">
        <p class="program-main-headline">メインパフォーマンス</p>
        <div class="program-main-inner">
          <template v-for="mainProgram in mainPrograms" :key="mainProgram.id">
            <div
              v-for="program in mainProgram.column1"
              :key="program.id"
              class="row flow program-main"
              :id="program.num"
            >
              <div class="col span-3">
                <img :src="program.img" alt="プログラム" />
              </div>
              <div class="col span-9 program-text">
                <h3>{{ program.ttl }}</h3>
                <p v-html="program.date"></p>
                <p v-html="program.txt"></p>
              </div>
            </div>
            <div
              v-for="program in mainProgram.column2"
              :key="program.id"
              class="row flow program-main"
              :id="program.num"
            >
              <div class="col span-3">
                <img :src="program.img" alt="プログラム" />
              </div>
              <div class="col span-9 program-text">
                <h3>{{ program.ttl }}</h3>
                <p v-html="program.date"></p>
                <p v-html="program.txt"></p>
                <BaseReserveBtn v-if="program.form" :link="program.link">
                  <template v-if="program.txtLink" v-slot:txtLink>
                    <span class="txt-link" v-html="program.txtLink"></span>
                  </template>
                </BaseReserveBtn>
              </div>
            </div>
          </template>
        </div>
      </div>
      <template v-for="row in Rows" :key="row.id">
        <div class="row program-sub">
          <template v-for="r in row" :key="r.id">
            <div class="col span-4" :id="r.num">
              <img :src="r.img" alt="プログラム" />
              <div class="col span-9 program-text">
                <h3>{{ r.ttl }}</h3>
                <p v-html="r.date"></p>
                <p v-html="r.txt"></p>
              </div>
              <BaseReserveBtn v-if="r.form" :link="r.link" />
            </div>
          </template>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import BaseReserveBtn from "@/components/base/BaseReserveBtn.vue";
import { ref, computed } from "vue";
export default {
  name: "ProgramList",
  components: {
    BaseReserveBtn,
  },
  setup() {
    const mainPrograms = ref([
      {
        column1: [
          {
            num: "program1",
            img: require("@/assets/img/program01.webp"),
            ttl: "1部 オーパ♪キャマラード合奏団「みんなで楽しいわくわくコンサート」(音楽)",
            date: "9/8(日) 17:15-17:45 ・ 別所神社",
            txt: "元NHKうたのおねえさんと実力派楽器のおねえさんたちの愉快なコンサート。童謡から世界の名曲まで、楽器体験あり！手遊びあり！ アクロバット演奏あり！ 大人から子どもまで楽しめるプログラムは工夫満載♫<br /><br />歌：城倉かほる<br />ヴァイオリン：李 文佳<br />クラリネット：高井洋子<br />ピアノ：飯田彰子",
            form: false,
          },
        ],
        column2: [
          {
            num: "program2",
            img: require("@/assets/img/program02.webp"),
            ttl: "2部「From ZERO -beyond-」(総合コラボレーション)",
            date: "9/8(日) 18:00-19:30 ・ 別所神社",
            txt: "別所温泉の風土や歴史と様々なアートが織り成す「From ZERO」がこの3年間の中で別所温泉と関わり地域住民の皆様と創り上げた集大成を描き出します。底知れずうごめく人間の“熱”と、別所の土地深くに眠る温泉の“熱”との交錯。己と向き合う鏡の中からドラマは始まる。<br /><br />演出、振付：山本 裕<br />ダンス：浅川奏瑛、カナキティ、輿一朗、NEphRiTE dance company(小倉藍歌、土田貴好、中林 柚、文屋七彩、吉倉咲季)、船木こころ、山之口理香子、山本 裕、米澤一平、ワカバコーヒー(川村真奈、若羽幸平)<br />ライブペイント：越ちひろ<br />演奏：植川 縁、小出稚子<br />舞台、照明、音響：株式会社長野三光<span class='and'>&</span><div class='half-program'><p class='half-program-ttl'>ハーフタイムショー「船木こころのドSストレッチ」</p>松茸&ensp;→&ensp;八角三重塔&ensp;→&ensp;ななくりの湯<br /><br />演出：山本 裕<br />振付、出演：船木こころ、輿一朗</div>",
            form: true,
            link: "https://teket.jp/10494/36490",
            txtLink:
              "メインイベントの申込は<span>こちらから ></span><br /><small>（1部・2部 共通チケット）</small>",
          },
        ],
      },
    ]);
    const subPrograms = ref([
      {
        num: "program3",
        img: require("@/assets/img/program03.webp"),
        ttl: "【オープニングセレモニー】(トーク、盆踊り、ライブペイント)",
        date: "9/5(木) 14:00-14:40 ・ 北向観音境内",
        txt: "舞踊家の山之口理香子と山本 裕が繰り広げる芸術祭やその作品にまつわるトーク。また西川小扇都指導のもと、ご列席者の皆様で盆踊り「別所温泉旅情」を踊ってみましょう。最後には画家の越ちひろが皆様と一緒に1つの龍の絵「雨龍 -URYU-」を描き上げます。子供から大人まで楽しく参加出来るオープニングセレモニーとなっています。<br /><small>※越ちひろライブペインティングにご参加の方は、汚れてもいい服装でお越しください。</small><br /><br />トーク：山之口理香子、山本 裕<br />盆踊り指導：西川小扇都<br />演奏：植川 縁、小出稚子<br />ライブペイント：越ちひろ",
        form: false,
      },
      {
        num: "program4",
        img: require("@/assets/img/program04.webp"),
        ttl: "《小倉藍歌✕土田貴好「restart」》(ダンス)",
        date: "9/5(木) 15:55-16:05 ・ 別所温泉駅",
        txt: "ここは<br />かつて自分が寝過ごした駅に似ていて<br />どこかなつかしさを感じる<br /><br />振付、ダンス：小倉藍歌、土田貴好",
        form: false,
      },
      {
        num: "program5",
        img: require("@/assets/img/program05.webp"),
        ttl: "《ワカバコーヒー with 米澤一平「あくまのしょうめい」》(舞踏✕ダンス✕タップ)",
        date: "9/5(木) 19:00-19:30(会場OPEN18:00-22:00) ・ 古民家カフェSmooth & Living<br />※一般：2,500円、サブスク会員：1,500円(ワンドリンク、軽食あり)",
        txt: "「あくまのしょうめい」は、熱海市「Atelier&Hostel ナギサウラ」主催の『MIZUMONO residence 2024』にて、ワカバコーヒーが「不在の喪失」をテーマに滞在制作した作品。今回はタップダンサーやキュレーターとして活躍中の米澤一平とコラボレーション。不在と戯れる三者三様の踊り手たちをお楽しみあれ！アフタートークあり。<br /><br />演出、構成：ワカバコーヒー<br />ドラマトゥルク：sumika<br />出演：ワカバコーヒー(川村真奈、若羽幸平)、米澤一平",
        form: true,
        link: "https://teket.jp/10494/36641",
      },
      {
        num: "program6",
        img: require("@/assets/img/program06.webp"),
        ttl: "【朝活ワークショップ ～山之口理香子✕manimanium「color」編～】(ダンス✕写真)",
        date: "9/6(金) 10:00-12:30 ・ 別所温泉センター<br />受講料：2,000円",
        txt: "このワークショップでは、山之口理香子の動きと色彩を組み合わせた写真作品を創り出す技術と芸術性を学びます。ダンサーの力強さや優雅さを、色彩の持つ感情やエネルギーとともに捉えることで、新たな視覚的表現を探求します。スマホなど、どんなカメラでも参加できます。お気軽にご参加ください。<br /><br />講師：山之口理香子、manimanium",
        form: true,
        link: "https://teket.jp/10494/36642",
      },
      {
        num: "program7",
        img: require("@/assets/img/program07.webp"),
        ttl: "《浅川奏瑛「TOKYO 21XX」》(ダンス)",
        date: "9/6(金) 15:00-15:15 ・ 安楽寺庭園",
        txt: "「第三次世界大戦がどのように行われるかは私にはわからない。だが、第四次世界大戦が起こるとすれば、その時に人類が用いる武器は棒切れと石ころだろう」-アルベルト・アインシュタイン<br />とある滅亡間近の惑星の話。<br />技術と想像力を手にした人類は、破壊と構築の中に生命循環の法則を置いてきてしまった。<br />ガラクタと化した街、心。血のついた足。<br />最期の信号によると、人類は最後の1人になるまで戦っていたそうだ。<br /><br />振付、ダンス：浅川奏瑛",
        form: false,
      },
      {
        num: "program8",
        img: require("@/assets/img/program08.webp"),
        ttl: "《カナキティ「コバルトブルーの呼吸」》(舞踏)",
        date: "9/6(金) 15:00-15:30 ・ 安楽寺庭園",
        txt: "僕の身体に走る碧の閃光。広がってゆけ、光。カナキティによる新世代舞踏パフォーマンス。<br /><br />振付、舞踏：カナキティ",
        form: false,
      },
      {
        num: "program9",
        img: require("@/assets/img/program09.webp"),
        ttl: "【常楽寺で繭クラフトを楽しもう！ワークショップ】(造形)",
        date: "9/7(土) 10:00-12:00、13:00-15:00 ・ 常楽寺本堂",
        txt: "羊の置物とモロコシストラップ、2種類のキットのうちお好きな1品を作ります。<br /><small>※講師が製作の指導をいたします。製作に必要なハサミ・バンド等は用意してありますので、手ぶらで参加できます。</small><br /><br /><div class='supp'>受講料：500円<br />お問い合わせ：<a class='phone-link' href='tel:0268371234'>0268-37-1234(常楽寺)</a></div>",
        form: false,
      },
      {
        num: "program10",
        img: require("@/assets/img/program10.webp"),
        ttl: "【朝活ワークショップ ～カナキティ「Bubble」編～】(舞踏✕瞑想)",
        date: "9/7(土) 10:30-12:00 ・ 別所温泉センター<br />受講料：2,000円",
        txt: "カナキティの舞踏メソッドに基づいた、瞑想ワークショップです。別所の温泉で癒された身体。朝の瞑想で身体の内側も整えていきましょう。皆で自身の中に潜ってみる。そんな旅のような時間にしたいと思います。ダンスをするわけでないので、どなたでもご参加頂けます。<br /><small>※お持ちでしたら白いお衣装(Tシャツでもワンピースでも何でもok)でお越しください。</small><br /><br />講師：カナキティ",
        form: true,
        link: "https://teket.jp/10494/36646",
      },
      {
        num: "program11",
        img: require("@/assets/img/program11.webp"),
        ttl: "【別所温泉ちんどん巡り】(音楽、パレード)",
        date: "9/7(土) 13:00-13:30 ・ 別所温泉エリア",
        txt: "音楽家の小出稚子、植川 縁、オーパ♪キャマラード合奏団(李 文佳、高井洋子)が創り上げるちんどん音楽隊。案内人の声掛けと共に地域住民の皆様と別所温泉エリアを楽しく練り歩きます。<br /><br />演奏：植川 縁、小出稚子、オーパ♪キャマラード合奏団(李 文佳、高井洋子)<br />案内人：船木こころ",
        form: false,
      },
      {
        num: "program12",
        img: require("@/assets/img/program12.webp"),
        ttl: "【上田電鉄✕別所温泉芸術祭コラボレーションプログラム①「君が行く道の長手を繰り畳ね..」】(ダンス✕音楽)",
        date: "9/7(土) 16:20-17:06 ・ 上田電鉄別所線 上田駅 〜 別所温泉駅",
        txt: "「君が行く道の長手を繰り畳ね..」は万葉集にある一節、元々は作者の恋人が流罪になった時に、恋人への遠き道を繰り畳ねて滅ぼしたいという思いで詠まれた歌です。私達はこの道の長手を物理的に無くすことは出来ません、しかし充実した楽しい時間というものはあっという間に過ぎていくもの。線路を走りながら即興的に創り上げるパフォーマンスの旅は乗車時間30分において繰り畳ねる道となり恋人達を救うことが出来るのでしょうか。<br /><br /><div class='supp'>上田電鉄別所線上田駅改札口にて16:20集合 → 上田駅 16:36発 → 別所温泉駅 17:06着<br />上田電鉄貸切電車<br />一般：前売2,500円、当日2,800円<br />中高生：前売1,500円、当日1,800円<br />小学生以下：無料(保護者同伴でお願いいたします。)</div><br />演出：山本 裕<br />パフォーマンス：浅川奏瑛、植川 縁、カナキティ、小出稚子、輿一朗、NEphRiTE dance company(小倉藍歌、土田貴好、中林 柚、文屋七彩、吉倉咲季)、船木こころ、山之口理香子、山本 裕、米澤一平、ワカバコーヒー(川村真奈、若羽幸平)",
        form: true,
        link: "https://teket.jp/10494/36643",
      },
      {
        num: "program13",
        img: require("@/assets/img/program13.webp"),
        ttl: "【上田電鉄✕別所温泉芸術祭コラボレーションプログラム②信州プロレスリング「別所線電車プロレス！」】(プロレス)",
        date: "9/7(土) 17:00-17:43 ・ 上田電鉄別所線 別所温泉駅 〜 上田駅",
        txt: "別所線全線開通100周年！そして東日本台風災害から5年！上田市出身のグレート☆無茶がそんな別所線への想いを胸に別所線の電車の中でプロレスを行います！安全第一、台本重視、入場無料、雨天検討の社会人お笑いプロレスごっこが繰り出すプロレスパフォーマンスを乗車無料、観戦無料でお楽しみください！<br /><br /><div class='supp'>上田電鉄別所線別所温泉駅改札口にて17:00集合 → 別所温泉駅 17:12発 → 上田駅 17:43着<br />上田電鉄貸切電車<br />乗車無料<br />限定40席(7月15日の長野市芸術館大会にて20席配布。残り20席はwebサイトにて8月15日の23時59分までエントリー受付生配信抽選会行います！)<br />詳しくは信州プロレスHPまで！<br /><a href='https://swfnagano.com/' target='_blank'>https://swfnagano.com/</a></div>",
        form: false,
      },
      {
        num: "program14",
        img: require("@/assets/img/program14.webp"),
        ttl: "【越ちひろ「雨龍 -URYU-」〜オープニングライブペインティング共同作品(9月5日実施)〜】(絵画)",
        date: "9/5(木)-9/8(木) 常設展示 ・ 別所温泉駅",
        txt: "オープニングセレモニー（北向観音境内）にて、越ちひろが来場者の皆様と即興で描いた約10分間のライブ作品を展示。",
        form: false,
      },
      {
        num: "program15",
        img: require("@/assets/img/program15.webp"),
        ttl: "【manimanium「flower of life」】(写真)",
        date: "9/5(木)-9/8(木) 常設展示 ・ 古民家カフェSmooth & Living(カフェ利用の際に鑑賞出来ます。)",
        txt: "花の持つ力強さと繊細さ。自然が織りなす美の一瞬をお楽しみください。ここにあるのは、日常の喧騒から離れ、自然の息吹を感じるための特別な空間です。",
        form: false,
      },
      {
        num: "program16",
        img: require("@/assets/img/program16.webp"),
        ttl: "【寺村サチコ「繭の海」「MY SWEET TOXICANTS Udimbara」】(インスタレーション)",
        date: "9/5(木)-9/8(木) 常設展示 ・ あいそめの湯ロビー(繭の海)、南條旅館ロビー(MY SWEET TOXICANTS Udimbara)",
        txt: "「繭の海」<br />繭や蚕をモチーフに、それらが羽化していく様子をイメージた立体作品です。<br /><br />「MY SWEET TOXICANTS Udimbara 」<br />滅多に咲くことのない竹の花が咲くのは不吉なことの起きる前触れであるというところから、空想上の植物が咲くと何が起きるのか、それは吉兆なのか、それとも逆なのかを考える作品として、「優曇華」という空想上の植物をモチーフに立体を制作しました。",
        form: false,
      },
    ]);
    const Rows = computed(() => {
      const result = [];
      for (let i = 0; i < subPrograms.value.length; i += 2) {
        result.push(subPrograms.value.slice(i, i + 2));
      }
      return result;
    });
    return {
      mainPrograms,
      subPrograms,
      Rows,
    };
  },
};
</script>

<style lang="scss" scoped>
.program {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 8rem;
  background: #fff;
  @include sp {
    padding-bottom: 2rem;
  }
}

.program .container {
  @include sp {
    padding: 0;
  }
}

.program-main-headline {
  max-width: 1200px;
  margin: 0 auto;
  color: #999;
  font-size: 2.8rem;
  font-weight: 600;
  @include sp {
    font-size: 2.3rem;
  }
}

.program-main-inner {
  position: relative;
  margin: 2rem 0;
  padding: 2rem 0;
  &::before {
    position: absolute;
    top: -1rem;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    content: "";
    background: #999;
  }
  &::after {
    position: absolute;
    bottom: 3rem;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    content: "";
    background: #999;
  }
}

.container .row .program-text {
  max-width: 50%;
  padding: 2rem;
  @include sp {
    max-width: 100%;
    padding: 0rem 2rem 2rem;
    line-height: 1.3;
    text-align: left;
  }
}

.program .container .program-main {
  justify-content: space-between;
}

.program .container .row {
  justify-content: space-evenly;
}

.program-text h3,
.program-sub h3 {
  font-weight: 600;
  @include sp {
    font-size: 2.3rem;
  }
}

.row .col.span-3 {
  flex: 0 0 45%;
  max-width: 75%;
  margin-bottom: 8rem;
  @include sp {
    max-width: 100%;
    margin-bottom: 4rem;
    text-align: center;
  }
  > img {
    border: 2px solid #000;
  }
}

.container .program-sub:last-child {
  &::after {
    content: "";
    display: block;
    width: 45%;
    margin-left: 4%;
  }
}

.container .program-sub .col {
  flex: 0 0 45%;
  max-width: 45%;
  margin-bottom: 8rem;
  @include sp {
    max-width: 100%;
    margin-bottom: 4rem;
  }
}

.program-sub:last-child > .col.span-4 {
  margin-bottom: 0;
}

.program-sub img {
  margin-bottom: 1rem;
  border: 2px solid #000;
}

.program .container .program-sub .program-last {
  margin-bottom: 0;
}

.program-name {
  margin-bottom: 0;
}

:deep(.half-program) {
  font-size: 1.4rem;
  margin: 0;
  > .half-program-ttl {
    font-size: 1.8rem;
    font-weight: 500;
  }
}

:deep(.and) {
  display: block;
  padding: 0.5em 0;
  font-size: 2rem;
  font-weight: 500;
}

.txt-link {
  :deep(> span) {
    display: inline-block;
  }
}

:deep(.supp) {
  margin: 0;
  padding: 0.5em;
  background: #f7f7f7;
}

:deep(.phone-link) {
  display: inline;
  color: #000;
  text-decoration: underline;
}

@property --angle {
  syntax: "<angle>";
  inherits: false;
  initial-value: 0deg;
}
</style>
