<template>
  <div class="main-movie-wrapper">
    <div class="main-movie">
      <video
        class="main-movieItem"
        poster="@/assets/img/befes3-movie-poster.png"
        preload="metadata"
        autoplay
        muted
        loop
        playsinline
        webkit-playsinline
      >
        <source src="@/assets/video/befes3-movie.webm" type="video/webm" />
        <source src="@/assets/video/befes3-movie.mp4" type="video/mp4" />
      </video>
    </div>
    <a
      class="main-movie-link"
      href="https://youtu.be/okfAab2TUcA"
      target="_blank"
    >
      YouTubeで観る
    </a>
  </div>
</template>

<script>
export default {
  name: "MainMovie",
};
</script>

<style lang="scss" scoped>
.main-movie-wrapper {
  position: relative;
  width: 100%;
}
.main-movie {
  width: 100%;
  > .main-movieItem {
    width: 100%;
    height: auto;
    vertical-align: bottom;
  }
}
.main-movie-link {
  position: absolute;
  right: 0;
  bottom: 20%;
  width: fit-content;
  padding: 0.4em 1.2em;
  color: #fff;
  font-size: #{"clamp(16px, 3vw ,24px)"};
  background: rgba(#000, 0.6);
}
</style>
